
import logo from './logo.svg';

function App() {
  return (
    <div className='m-6'>
        <img src={logo} className="animate-spin-slow" alt="logo" />
        <div className='content-center'>
        <p className='text-3xl py-3 text-center text-gray-600 font-medium'>
          Copilot demo: building channel page using Open Alex API
        </p>
        <ul className='list-decimal space-y-2 text-gray-700'>
          <li>A React component that <b>fetches data from Open Alex API</b></li>
          <li>A React component that <b>renders API data into a channel page</b></li>
          <li>Use <b>TailwindCSS</b> for quick styling</li>
          <li>Mock Open Alex API using <b>Mock Service Worker</b></li>
          <li>Write React component <b>Unit test</b>that utlises the mock api</li>
          <li>Deploy the channel page React app into <b>Cloudflare Workers platform</b></li>
        </ul>
        </div>
    </div>
  );
}

export default App;